.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #df1313;
}

.link {
  color:#727272;
  text-decoration: none;
  height: 100%;
  
}
.card {
  /*background-image: url(./images/white2.jpg) ;*/
  border-radius: 50px;
}

.autocomplete:focus-visible {
  outline: none !important;
border-color: #BCBABA;
}

.autocomplete:focus {
  box-shadow: 1px 4px 7px red;
}

.action-key {
  color:darkred;

}
textarea:focus, input:focus{
  outline: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
