

.buttonspread {
    display: flex;
    justify-content: space-around;
    margin-bottom: 30px;
}

tr {
    border-width:1px ;
    border-color: dimgrey;
}

td {
    min-width: 100px;
    border-width:1px ;
    border-color: dimgrey;
    padding-left: 10px;
}

td:hover {
    min-width: 100px;
    border-width:1px ;
    border-color: dimgrey;
    background-color: rgb(187, 0, 0);
    color: rgb(255, 255, 255);
    padding-left: 10px;
}

tr:hover {
    
    border-width:1px ;
    border-color: dimgrey;
    background-color: rgb(122, 122, 122);
    color: rgb(255, 255, 255);
    padding-left: 10px;
}