
.navbar {
    width: 100%;
    display: flex;
    background-color: white;
    padding: 0;
    justify-content: left;
    
  
    
    
    
}

.menu {
    Height: 60px;
    display: flex;
    margin-left: 30px;
    background-color: #ffffff;
    align-items: flex-start;
    padding: 0;
  
    
    
}


@media screen and (max-width: 768px) {
    .menu {
        width: 100%;
        display: block;
        margin-left: 0px;
        height: 100%;
        
        
    }
    .dropdown {
        position: relative;
        display: inline-block;
       width: 100%;
      }
}

.logo {
margin-left: 30px;

}

.menuItem {
    padding: 10px;
    color: gray;
    font-weight: 400;
    
    
}

.menuItem:hover {
    padding: 10px;
    color: rgb(196, 18, 18);
    background-color: rgb(255, 255, 255);
    height: 100%;
}

.menuItem:active {
    background-color: #ffffff;
    color: #3d3d3d;
  }

.dropdown {
    position: relative;
    display: inline-block;
    height: 100%;
   
  }

  .dropdown-content {
      top: 60px;
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 230px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }

  .seperate-right {
    
      position: absolute;
      right: 10px;
     
  }

